import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/styles.scss';
import $ from 'jquery';
import Inputmask from "inputmask";

function importAll(r) {
  return r.keys().map(r);
}

importAll(require.context('./images/', false, /\.(png|jpe?g|gif|svg)$/));
importAll(require.context('./fonts/', false, /\.(eot|ttf|woff|svg)$/));

import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init({
  offset: 150, // offset (in px) from the original trigger point
  duration: 700, // values from 0 to 3000, with step 50ms
});

function getParameterByName(name, url = window.location.href) {
    name = name.replace(/[\[\]]/g, '\\$&');
    const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

$('#modal1').on('show.bs.modal', function (event) {
  const button = $(event.relatedTarget);
  const action = button.data('action');
  modal1.querySelector('.modal-title').innerText = action;
  modal1.querySelector('.main-button').innerText = action;
})

if (getParameterByName('form') === 'success') {
  $('#modal5').modal('show');
  let params = new URLSearchParams(url.search.slice(1));
  params.delete('form');
}

if (getParameterByName('form') === 'fail') {
  $('#modal6').modal('show');
  let params = new URLSearchParams(url.search.slice(1));
  params.delete('form');
}

// Inputmask().mask(document.querySelectorAll("input"));

const currentDate = new Date();
const experience = currentDate.getFullYear() - 2011;
let workExperience = '' + experience;
const lastSymbol = workExperience[workExperience.length - 1];

if (experience < 20) {
  workExperience += ' лет';
} else if (lastSymbol === '1') {
  workExperience += ' год';
} else if (['2', '3', '4'].includes(lastSymbol)) {
  workExperience += ' года';
} else {
  workExperience += ' лет';
}

document.querySelector('.work-experience-js').innerText = workExperience;
